<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDialog">
      <md-dialog-title>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6 large-8">
            <i class="fi-torsos-all-female"></i>&nbsp;&nbsp;&nbsp;Dokumentation Benutzer-Profile
          </div>
          <div class="cell hide-for-small-only medium-6 large-4" style="padding-top: 0px; margin-top: -8px;">
            <md-button class="md-dense md-primary" @click="closeDialog">
              <md-icon>close</md-icon>
              Ansicht schliessen und zurück
              <md-tooltip md-direction="bottom" style="font-size: medium;">
                Ansicht schliessen und zurück
              </md-tooltip>
            </md-button>
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content>
        <div class="md-content">
          <md-tabs md-dynamic-height>
            <md-tab md-label="Benutzer-Profile">
              <div v-html="userProfilesDocumentationHtml"></div>
            </md-tab>
            <md-tab md-label="Zugriffsberechtigungen">
              <div v-html="userProfilesTableHtml"></div>
            </md-tab>
          </md-tabs>
        </div>
      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>
import userProfilesDescriptionsMD from '../../../assets/md/profiles-documentation.md';
import userProfilesTableMD from '../../../assets/md/profiles-table.md';
import showdown from "showdown";
import userProfilesDocumentationDialog from "@/components/dialogs/documentation/UserProfilesDocumentationDialog.vue";

export default {

  name: 'UserProfilesDocumentationDialog',

  components: {},

  props: {
    showDialog: false,
  },

  created() {
    this.userProfilesDocumentationHtml = this.formatMarkdown(userProfilesDescriptionsMD);
    this.userProfilesTableHtml = this.formatMarkdown(userProfilesTableMD);
  },

  data: () => ({
    userProfilesDocumentationHtml: '',
    userProfilesTableHtml: '',
  }),

  methods: {

    formatMarkdown(markDown) {
      let converter = new showdown.Converter();
      markDown = markDown.replaceAll('⃠', '<span style="color: red;font-weight: bold;">⃠</span>');
      if (this.isDarkMode) {
        markDown = markDown.replaceAll('✓', '<span style="color: limegreen;font-weight: bold;">✓</span>');
      } else {
        markDown = markDown.replaceAll('✓', '<span style="color: seagreen;font-weight: bold;">✓</span>');
      }
      return converter.makeHtml(markDown);
    },

    closeDialog() {
      this.$emit("closeShowUserProfilesDocumentationDialog");
    }
  },

  computed: {
    userProfilesDocumentationDialog() {
      return userProfilesDocumentationDialog
    },

    isDarkMode() {
      return this.$store.getters.darkMode
    },

    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    isAdmin() {
      return this.$store.getters.admin
    },

    isSage() {
      return this.$store.getters.sage
    },

    organization() {
      return this.$store.getters.organization
    },

    labels() {
      return this.$store.getters.labels
    },
  },
};
</script>

<style scoped>
.md-dialog /deep/ .md-dialog-container {
  min-width: 100%;
  min-height: 100%;
}
.md-dialog-content {
  padding: 0 10px 10px;
}
</style>

