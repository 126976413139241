<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><i class="fi-torsos-all-female"></i>&nbsp;&nbsp;&nbsp;Benutzer
      </h3></div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <md-table id="users-table-id" v-model="searched" md-sort="loginTimeStamp" md-sort-order="desc" md-card
                  md-fixed-header
                  @md-selected="onSelect" :style="tableStyle">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
              <div class="cell large-3 medium-4 small-10">
                <p class="md-title ai-number">{{ searched.length }}&nbsp;Benutzer</p>
                <p v-if="users.length - searched.length > 0" style="color: crimson; margin: 0; padding-left: 10px;">
                  {{ users.length - searched.length }} nicht sichtbar
                  <md-tooltip style="font-size: medium; height: auto; white-space: pre-wrap;" md-direction="bottom"
                              md-delay="500">
                    <b>Warum nicht sichtbar?</b><br><br>
                    <span>Durch setzen von Filtern wird die Liste eingeschränkt.</span><br><br>
                    <span>Klicken Sie auf <md-button class="md-icon-button md-raised md-primary"
                                                     style="background: cornflowerblue; margin-bottom: 10px">
                                  <md-icon style="color: white">select_all</md-icon>
                                </md-button> um alle Einträge zu sehen.</span>
                  </md-tooltip>
                </p>
                <p v-else style="color: seagreen; margin: 0; padding-left: 10px;">
                  Alle sichtbar
                </p>
              </div>
              <div class="cell large-1 medium-2 small-2">
                <div v-if="sending">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
                <div v-else>
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <md-button @click="showDocumentation"
                                 :disabled="sending"
                                 class="md-icon-button md-raised md-forth">
                        <md-icon>help</md-icon>
                        <md-tooltip>Hilfe zu den Benutzerprofilen</md-tooltip>
                      </md-button>
                      <md-button class="md-icon-button md-raised md-primary" style="background: cornflowerblue"
                                 @click="onShowUsers">
                        <md-icon>select_all</md-icon>
                        <md-tooltip md-direction="bottom" style="font-size: medium;">
                          Alle <b>{{ users.length }}</b> Benutzer <span
                            v-if="organization.name != 'ai42'">des Trägers <b>{{ organization.name }}</b></span>
                          anzeigen
                        </md-tooltip>
                      </md-button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="isSage" class="cell large-8 medium-6 small-12">
                <md-autocomplete id="organization-name-id" v-model="selectedOrganizationName"
                                 :md-options="availableOrganizationNames"
                                 @md-changed="setOrganizations" @md-opened="setOrganizations"
                                 @md-selected="selectOrganization">
                  <label>Träger</label>

                  <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                    <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                  </template>

                  <template slot="md-autocomplete-empty" slot-scope="{ term }">
                    "{{ term }}" nicht gefunden
                  </template>

                </md-autocomplete>

              </div>
              <div v-if="isSage" class="cell large-4 medium-6"></div>

              <div class="cell large-8 medium-6 small-12">
                <md-field>
                  <label>Berechtigt für Einrichtung</label>
                  <md-select v-if="selectedFacilityReferenceNumbers" v-model="selectedFacilityReferenceNumbers"
                             :disabled="sending || availableFacilities.length < 1"
                             @md-selected="onSelectFacilities" multiple>
                    <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                               :key="facility.id">
                      {{ facility.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-4 hide-for-medium-only"></div>
              <div class="cell large-4 medium-6 small-12">
                <md-field>
                  <label>Benutzer-Profil</label>
                  <md-select v-model="selectedProfiles" autocomplete="off" :disabled="sending"
                             @md-selected="onSelectProfile" multiple>
                    <md-option v-for="profile of labels.availableProfiles" :value="profile" :key="profile">
                      {{ labels.rolesprofile[profile] }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-4 medium-6 small-12">
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche Email ..." v-model="search" @input="searchOnTable"/>
                </md-field>
              </div>
              <div class="cell" style="padding-bottom: 15px;">
                <div class="grid-x grid-padding-x">
                  <div class="cell large-8 medium-6 hide-for-small-only">
                  </div>
                  <div class="cell large-4 medium-6">
                    <md-button v-if="isSage" @click="createUserDialog"
                               :disabled="sending"
                               class="md-icon-button md-raised md-primary">
                      <md-icon>person_add</md-icon>
                      <md-tooltip>Neuen Benutzer anlegen</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin && !isSage" @click="addUserDialog"
                               :disabled="sending"
                               class="md-icon-button md-raised md-primary">
                      <md-icon>person_add</md-icon>
                      <md-tooltip>Benutzer hinzufügen</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="editUserDialog"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-third">
                      <md-icon>edit</md-icon>
                      <md-tooltip>Benutzer bearbeiten</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="deleteUserDialog"
                               :disabled="!selected || (selected && selected.id === user.id)"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>delete</md-icon>
                      <md-tooltip>Benutzer löschen</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="unlockUserDialog"
                               :disabled="!selected || (selected && selected.locked === false)"
                               class="md-icon-button md-raised md-forth">
                      <md-icon>lock_open</md-icon>
                      <md-tooltip>Benutzer entsperren</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </div>

            </div>
          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keinen Benutzer gefunden"
                                :md-description="`Keinen Benutzer mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff oder legen Sie einen neuen Benutzer an.`">
          </md-table-empty-state>

          <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                        md-selectable="single">
            <md-table-cell v-if="isSage" md-label="Kundennummer" md-sort-by="customerNo" md-numeric>
              {{ item.customerNo.substr(0, 10) }}<span v-if="item.customerNo.length > 9">...</span>
              <md-tooltip style="font-size: medium;" md-direction="top" md-delay="500">
                <div v-if="availableCustomerNos[item.customerNo]">
                  Kundennummer: <b>{{ availableCustomerNos[item.customerNo] }}</b>
                </div>
                <div v-else>
                  Kundennummer: <b>{{ item.customerNo }}</b>
                </div>
              </md-tooltip>
            </md-table-cell>
            <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}
              <md-tooltip class="block" md-direction="top" md-delay="500">
                Vorname : <b>{{ item.firstName }}</b>
                Nachname : <b>{{ item.lastName }}</b><br><br>
                Berechtigte Einrichtungen :<br><b v-html="item.allowedFacilitiesString"></b>
              </md-tooltip>
            </md-table-cell>
            <md-table-cell md-label="DSGVO" md-sort-by="dsgvoConfirmationObtainedStr">
              <span v-if="item.dsgvoConfirmationObtained"><md-icon style="color: green">done</md-icon>
                <md-tooltip md-delay="500">Einwilliung Datenverarbeitung : <b>gegeben</b></md-tooltip>
              </span>
              <span v-else><md-icon style="color: orangered">clear</md-icon>
                <md-tooltip md-delay="500">Einwilliung Datenverarbeitung : <b
                    style="color: orangered;">Nicht gegeben</b></md-tooltip>
              </span>
            </md-table-cell>
            <md-table-cell md-label="Profil" md-sort-by="rolesProfileLabel">{{ item.rolesProfileLabel }}</md-table-cell>
            <md-table-cell v-if="isSage" md-label="Beta" md-sort-by="betaStr"><span v-if="item.beta"><i
                style="color: dodgerblue">ja</i></span><span v-else><i>nein</i></span></md-table-cell>
            <md-table-cell md-label="Aktiv" md-sort-by="activeStr"><span v-if="item.active"><i
                style="color: dodgerblue">ja</i></span><span v-else><i>nein</i></span></md-table-cell>
            <md-table-cell md-label="Aktivität" md-sort-by="activity"><span><i
                style="color: dodgerblue">{{ item.activity | number }}</i></span>
              <md-tooltip md-delay="500">Max Anmeldungen pro Tag (gemessen an den letzten 50) :
                <b>{{ item.activity | number }}</b><br>
              </md-tooltip>
            </md-table-cell>
            <md-table-cell md-label="Gesperrt" md-sort-by="lockedStr">
              <span v-if="item.locked"><i style="color: orangered">ja</i></span>
              <span v-else><i style="color: green">nein</i></span>
            </md-table-cell>
            <md-table-cell md-label="Aktuelle Anmeldung" md-sort-by="loginTimeStamp">
              {{ item.loginTimeSortString }}
              <md-tooltip class="block" md-direction="top" md-delay="500">
                <span>Anmeldungsinformationen : <b>{{ item.firstName }} {{ item.lastName }}</b></span><br><br>
                <span>Aktuelle Anmeldung : <b>{{ item.loginTimeString }}</b></span><br>
                <span>Letzte Anmeldung : <b>{{ item.lastLoginTimeString }}</b></span><br>
                <span>Ungültige Anmeldeversuche : <b>{{ item.invalidLoginAttempts }}</b></span>
              </md-tooltip>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteUserDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Benutzer löschen</md-dialog-title>
        <div class="cell">
          <md-content>Soll der Benutzer <b>{{ selected.username }}</b> wirklich gelöscht werden?</md-content>
        </div>
        <div class="cell text-center">
          <div v-if="isAdmin" @click="onDeleteUser" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Benutzer jetzt löschen
          </div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="showDeleteUserDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteUserDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showUnlockUserDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-unlock"></i>&nbsp;&nbsp;&nbsp;Benutzer entsperren</md-dialog-title>
        <div class="cell">
          <md-content>Soll die Sperre für Benutzer <b>{{ selected.username }}</b> wirklich aufgehoben werden?
          </md-content>
        </div>
        <div class="cell text-center">
          <div v-if="isAdmin" @click="onUnlockUser" class="button alert"><i class="fi-unlock"></i>&nbsp;&nbsp;&nbsp;Ja,
            Benutzer jetzt entsperren
          </div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="showUnlockUserDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showUnlockUserDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <EditUserDialog ref="editUserDialog" :showDialog="showEditUserDialog" :user="selectedUser" :mode="dialogMode"
                    :availableFacilities="availableFacilities" :availableCustomerNo="availableCustomerNo"
                    :availableCustomerNos="availableCustomerNos"
                    @updateUserSuccess="updateUserSuccess"
                    @closeEditUserDialog="showEditUserDialog = false"></EditUserDialog>

    <UserProfilesDocumentationDialog :showDialog="showUserProfilesDocumentationDialog"
                                     @closeShowUserProfilesDocumentationDialog="showUserProfilesDocumentationDialog = false"></UserProfilesDocumentationDialog>
  </div>
</template>

<script>
import jQuery from 'jquery';
import Spinner from 'vue-simple-spinner'
import UserService from '../services/UserService';
import FacilityService from '../services/FacilityService';
import OrganizationService from '../services/OrganizationService';
import HttpErrorHandler from '../services/HttpErrorHandler';

import EditUserDialog from '../components/dialogs/EditUserDialog';
import UserProfilesDocumentationDialog from '@/components/dialogs/documentation/UserProfilesDocumentationDialog.vue';

import User from "../entities/User"
import Vue from 'vue';


const toLower = text => {
  return text.toString().toLowerCase();
};

const searchByEmail = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.email).includes(toLower(term)));
  }

  return items;
};

const searchByCustomerNo = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.customerNo).includes(toLower(term)));
  }

  return items;
};

const searchByFacility = (items, term) => {
  if (term) {
    return items.filter(item => {
      for (const facility of item.allowedFacilities) {
        if (facility === term || facility === '*') {
          return true;
        }
      }
      return false;
    });
  }

  return items;
};

const searchByProfiles = (items, term) => {
  if (term) {
    return items.filter(item => term.includes(item.rolesProfile));
  }

  return items;
};

export default {
  name: 'Users',
  components: {
    EditUserDialog,
    UserProfilesDocumentationDialog,
    User,
    'vue-simple-spinner': Spinner
  },

  created() {
    this.initSyncHandler(true);
  },

  beforeDestroy() {
    this.initSyncHandler(false)
  },

  mounted() {
    this.restoreUserSettings();
    this.reloadUsers();
    if (this.isSage) {
      this.getOrganizations();
    }
    this.onShowUsers();
  },

  updated() {
    if (this.isSage && !this.selectedOrganizationName && this.selectedFacilityReferenceNumbers.length > 0) {
      this.selectOrganization(this.selectedOrganizationName);
      this.onShowUsers();
    }
  },

  data() {
    return {
      sending: false,
      users: [],
      searched: [],
      search: null,
      selected: null,
      showDeleteUserDialog: false,
      showUnlockUserDialog: false,
      showEditUserDialog: false,
      showUserProfilesDocumentationDialog: false,
      success: false,
      lastUser: '',
      dialogMode: 'update',
      selectedUser: null,
      availableFacilities: [],
      availableFacilitiesMap: {},
      selectedFacilityReferenceNumbers: [],
      selectedProfiles: [],

      allAvailableFacilities: [],

      organizations: [],
      availableCustomerNo: [],
      availableCustomerNos: {},
      selectedCustomerNo: null,

      selectedOrganizationName: '',
      availableOrganizations: [],
      availableOrganizationNames: [],
    }
  },

  methods: {

    initSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#organization-name-id').children('.md-menu').children('input').bind('focus', () => {
            setTimeout(() => jQuery('#organization-name-id').children('.md-menu').children('input').trigger('click'), 250);
          });
          jQuery('.md-table-content.md-scrollbar.md-theme-default').removeAttr('style');
        }, 500);
      } else {
        jQuery('#organization-name-id').children('input').unbind('focus');
      }
    },

    onOpenOrganizationSelector() {
      setTimeout(() => jQuery('.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content').css('max-width', '600px'), 50);
    },

    getOrganizations() {
      this.sending = true;
      OrganizationService.getAllOrganizations()
          .then(response => {
            this.sending = false;
            this.organizations = response.data;

            this.availableCustomerNo = [];
            this.availableCustomerNos = {};
            this.availableOrganizations = [];
            for (let organization of this.organizations) {
              this.availableCustomerNo.push(organization.customerNo);
              let organizationFullName = organization.customerNo + ' - ' + organization.name + ' (' + this.labels.organizationType[organization.organizationType] + ')';
              this.availableCustomerNos[organization.customerNo] = organizationFullName;
              this.availableOrganizations.push({customerNo: organization.customerNo, name: organizationFullName});
            }
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Träger');
          })
    },

    onShowUsers() {
      this.selectedCustomerNo = null;
      this.selectedOrganizationName = '';
      if (this.isSage) {
        this.buildAvailableFacilities(this.allAvailableFacilities);
      }
      this.selectedFacilityReferenceNumbers = [];
      this.selectedProfiles = this.labels.availableProfiles;
      this.search = null;
    },

    restoreUserSettings() {
      if (localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumbers')) {
        this.selectedFacilityReferenceNumbers = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumbers'));
        if (this.selectedFacilityReferenceNumbers === null) {
          this.selectedFacilityReferenceNumbers = [];
        }
      }
    },

    selectUserId() {
      let id = this.$route.params['id'];
      if (id) {
        this.users.forEach(user => {
          if (user.id === id) {
            setTimeout(() => {
              jQuery('#' + id).click()
              let list = jQuery('#users-table-id').children().first().next().next();
              list.animate({scrollTop: document.getElementById(id).offsetTop}, 'slow');
            }, 500);
          }
        });
      }
    },

    storeUserSettings() {
      if (this.selectedFacilityReferenceNumbers) {
        localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumbers', JSON.stringify(this.selectedFacilityReferenceNumbers));
      }
    },

    buildAvailableFacilities(facilities) {
      this.availableFacilities = facilities;
      this.availableFacilitiesMap['*'] = 'Alle';
      for (let i = 0; i < this.availableFacilities.length; i++) {
        if (this.availableFacilities[i].referenceNumber === '*') {
          // this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
        }
        this.availableFacilitiesMap[this.availableFacilities[i].referenceNumber] = this.availableFacilities[i].name;
      }

      if (this.isSage) {
        let filteredAvailableFacilities = [];
        filteredAvailableFacilities.push({referenceNumber: '*', name: 'Alle'})
        this.availableFacilities.forEach(facility => {
          if (facility.customerNo === this.selectedCustomerNo) {
            filteredAvailableFacilities.push(facility);
          }
        })

        this.availableFacilities = filteredAvailableFacilities;
      }
    },

    reloadUsers() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {
          if (this.isSage) {
            this.getAllFacilities();
          } else {
            this.buildAvailableFacilities(this.getAvailableFacilities);
            if (this.isAdmin && !this.isFacilityAdmin) {
              this.getAllUsers();
            }
          }

          clearInterval(reloadIntervalId);
        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    getAllFacilities() {
      this.sending = true;
      FacilityService.getAllFacilities()
          .then(response => {
            this.sending = false;
            this.allAvailableFacilities = response.data;
            this.buildAvailableFacilities(this.allAvailableFacilities);
            this.getAllUsers();
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Einrichtungen');
          })
    },

    getAllUsers() {
      this.sending = true;
      UserService.getAllUsers()
          .then(response => {
            this.sending = false;
            this.users = response.data;
            this.buildUserInformationDetails();
            this.searchOnTable();
            this.selectUserId();
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Benutzerdaten');
          })
    },

    buildUserInformationDetails() {
      for (let i = 0; i < this.users.length; i++) {
        this.users[i].allowedFacilitiesString = '';
        if (this.users[i].allowedFacilities) {
          let all = false;
          for (let facility of this.users[i].allowedFacilities) {
            if (facility === '*') {
              all = true;
              let j = 1;
              for (let fa of this.availableFacilities) {
                if (((fa.referenceNumber !== '*') && (fa.customerNo === this.users[i].customerNo))) {
                  this.users[i].allowedFacilitiesString += '(' + j++ + ') ' + this.availableFacilitiesMap[fa.referenceNumber] + '<br>';
                }
              }

              if (j <= 1) {
                this.users[i].allowedFacilitiesString += '<i>(Keine Einrichtungen)</i>';
              } else {
                this.users[i].allowedFacilitiesString = '<b>Alle</b> :<br>' + this.users[i].allowedFacilitiesString;
              }
            }
          }
          if (!all) {
            let j = 1;
            for (let facility of this.users[i].allowedFacilities) {
              this.users[i].allowedFacilitiesString += '(' + j++ + ') ' + this.availableFacilitiesMap[facility] + '</br>';
            }
          }
        }

        if (this.users[i].allowedFacilitiesString === '') {
          this.users[i].allowedFacilitiesString = 'keine';
        }
        this.users[i].loginTimeString = 'nicht verfügbar';
        this.users[i].loginTimeSortString = 'nicht verfügbar';
        this.users[i].loginTimeStamp = '';
        if (this.users[i].loginTime) {
          this.users[i].loginTimeString = Vue.moment(this.users[i].loginTime).format('dddd DD.MM.YYYY LTS') + ' Uhr';
          this.users[i].loginTimeSortString = Vue.moment(this.users[i].loginTime).format('DD.MM.YYYY LTS');
          this.users[i].loginTimeStamp = Vue.moment(this.users[i].loginTime).valueOf();
        }
        this.users[i].lastLoginTimeString = 'nicht verfügbar';
        if (this.users[i].lastLoginTime) {
          this.users[i].lastLoginTimeString = Vue.moment(this.users[i].lastLoginTime).format('dddd DD.MM.YYYY LTS') + ' Uhr';
        }

        this.users[i].dsgvoConfirmationObtainedStr = this.users[i].dsgvoConfirmationObtained.toString();
        this.users[i].betaStr = this.users[i].beta.toString();
        this.users[i].activeStr = this.users[i].active.toString();
        this.users[i].lockedStr = this.users[i].locked.toString();
      }
    },

    showDocumentation() {
      this.showUserProfilesDocumentationDialog = true;
    },

    createUserDialog() {
      this.dialogMode = 'create';
      this.selectedUser = JSON.parse(JSON.stringify(User.user));
      if (this.selectedUser && this.selectedUser.allowedFacilities === null) {
        this.selectedUser.allowedFacilities = [];
      }
      this.selectedUser.customerNo = this.organization.customerNo;
      this.showEditUserDialog = true;
    },

    addUserDialog() {
      this.dialogMode = 'add';
      this.selectedUser = JSON.parse(JSON.stringify(User.user));
      this.selectedUser.customerNo = this.organization.customerNo;
      if (this.selectedUser && this.selectedUser.allowedFacilities === null) {
        this.selectedUser.allowedFacilities = [];
      }
      this.showEditUserDialog = true;
    },

    deleteUserDialog() {
      if (this.selected) {
        this.showDeleteUserDialog = true;
      }
    },

    unlockUserDialog() {
      if (this.selected) {
        this.showUnlockUserDialog = true;
      }
    },

    editUserDialog() {
      if (this.selected) {
        this.dialogMode = 'update';
        this.selectedUser = JSON.parse(JSON.stringify(this.selected));
        this.selectedUser.initialpw = '';
        if (this.selectedUser && this.selectedUser.allowedFacilities === null) {
          this.selectedUser.allowedFacilities = [];
        }
        this.showEditUserDialog = true;
      }
    },

    updateUserSuccess() {
      this.showEditUserDialog = false;
      this.lastUser = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

      if (this.dialogMode === 'update') {
        this.$store.commit('successMsg', 'Die Änderungen für Benutzer <b>' + this.lastUser + '</b> wurden erfolgreich gespeichert!');
        this.success = true;
      }
      if (this.dialogMode === 'add') {
        this.$store.commit('successMsg', 'Der Benutzer <b>' + this.lastUser + '</b> wurde erfolgreich hinzugefügt.');
        this.success = true;
      }
      if (this.dialogMode === 'create') {
        this.$store.commit('successMsg', 'Der Benutzer <b>' + this.lastUser + '</b> wurde erfolgreich angelegt!');
        this.success = true;
      }

      this.success = true;
      this.selected = null;
      this.getAllUsers();
    },

    onSelectProfile() {
      this.searchOnTable();
    },

    onSelectFacilities() {
      this.storeUserSettings();
      this.searchOnTable();
    },

    onSelectCustomerNo() {
      this.availableFacilities = [];
      if (this.isSage && this.selectedCustomerNo !== '' && this.selectedCustomerNo !== 'undefined') {
        this.buildAvailableFacilities(this.allAvailableFacilities);
      }
      this.searchOnTable();
    },

    onDeleteUser() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        UserService.deleteUser(this.selected.id)
            .then(response => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Benutzer <b>' + this.selected.username + '</b> wurde erfolgreich gelöscht.');
              this.success = true;
              this.users = response.data;
              this.searchOnTable();
              this.search = null;
              this.selected = null;
              this.showDeleteUserDialog = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen von Benutzer ' + this.selected.username);
              this.sending = false;
            })
      }
    },

    onUnlockUser() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        UserService.unlockUser(this.selected)
            .then(response => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Benutzer <b>' + this.selected.username + '</b> wurde erfolgreich entsperrt!');
              this.success = true;
              this.search = null;
              this.selected = response.data;
              this.showUnlockUserDialog = false;
              this.reloadUsers();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen von Benutzer ' + this.selected.username);
              this.sending = false;
            })
      }
    },

    searchOnTable() {
      for (let user of this.users) {
        user.rolesProfileLabel = this.labels.rolesprofile[user.rolesProfile];
      }

      this.searched = searchByEmail(this.users, this.search);

      if (this.isSage) {
        this.searched = searchByCustomerNo(this.searched, this.selectedCustomerNo);
      }

      for (let refNum of this.selectedFacilityReferenceNumbers) {
        this.searched = searchByFacility(this.searched, refNum);
      }

      this.searched = searchByProfiles(this.searched, this.selectedProfiles);
    },

    getClass: ({id}) => ({
      'md-primary': id === id
    }),

    onSelect(item) {
      if (item) {
        this.selected = JSON.parse(JSON.stringify(item));
        if (this.isSage) {
          this.selectedCustomerNo = this.selected.customerNo;
          this.buildAvailableFacilities(this.allAvailableFacilities);
        }
      } else {
        this.selected = null;
      }
    },

    setOrganizations(searchTerm) {
      this.availableOrganizationNames = new Promise(resolve => {
        window.setTimeout(() => {
          if (!searchTerm) {
            resolve(this.availableOrganizations)
          } else {
            const term = searchTerm.toLowerCase();
            resolve(this.availableOrganizations.filter(({name}) => name.toLowerCase().includes(term)))
          }
        }, 5)
      })
    },

    selectOrganization(organization) {
      if (organization) {
        this.selectedCustomerNo = organization.customerNo;
        this.selectedOrganizationName = organization.name;
      }
      this.selectedFacilityReferenceNumbers = [];
      this.onSelectCustomerNo()
    },
  },

  computed: {

    tableStyle() {
      return this.$store.getters.sage ? 'min-height: 720px; height: calc(100vh - 345px); overflow-x: hidden' : 'min-height: 620px; height: calc(100vh - 349px); overflow-x: hidden'
    },

    dataAvailable() {
      return (this.$store.getters.sage && (this.user && this.user.allowedFacilities && this.user.allowedFacilities.length > 0 && this.labels)) ||
          (!this.$store.getters.sage && (this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
              this.user.allowedFacilities.length > 0 && this.labels &&
              this.getAvailableFacilities && this.getAvailableFacilities.length > 0));
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    organization() {
      return this.$store.getters.organization;
    },

    user() {
      return this.$store.getters.user;
    },

    labels() {
      return this.$store.getters.labels
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },
  }
}
</script>

<style lang="scss" scoped>

.dynamic-height {
  height: calc(100vh - 349px);
  overflow-x: hidden;
}

.md-dialog /deep/ .md-dialog-container {
  max-width: 800px;
}

i {
  color: black;
}

.md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
  background: darkseagreen;
}

.md-tooltip {
  font-size: medium;
  white-space: normal;
}

.md-tooltip.block {
  font-size: medium;
  height: auto;
  width: 500px;
  white-space: pre-wrap;
}
</style>
